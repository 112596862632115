import React from 'react';

import Button, {ButtonSizes, ButtonTypes} from '../../components/ButtonNew';
import Stars from '../../components/Stars';
import YouTubeVideo from '../../components/YouTubeVideo';
import {LANDING_VIDEO} from '../../constants/coaching';


const CoachLandingHero = ({
  pageTitle, pageSubtitle, onClickCTA, showTestimonial,
}) => (
  <div className="max-w-7xl mx-auto pt-6 pb-6 justify-center text-center flex md:pt-12 md:pb-12 md:flex-col">
    <div className="items-center md:flex md:gap-12">
      <div className="justify-center text-center md:w-2/3 lg:w-3/5 md:justify-start md:text-left">
        <span className="md:hidden inline-flex mb-2 uppercase tracking-wider text-indigo-600 mx-auto font-semibold">Coaching</span>
        <h1 className="text-2xl md:text-5xl text-black font-black leading-none">
          {pageTitle}
        </h1>
        <h3 className="mt-3 mb-6 text-base md:text-xl text-gray-500 font-medium leading-tight max-w-xl">
          {pageSubtitle}
        </h3>
        <div className="mt-4 mb-6 flex flex-col justify-center items-center sm:flex-row space-y-3 sm:space-y-0 md:justify-start">
          <Button
            size={ButtonSizes.LARGE}
            onClick={onClickCTA}
            className="w-full mr-2 sm:w-auto"
          >
            Find your coach
          </Button>
        </div>
        {showTestimonial && (<Stars rating="4.8 rating from over 2,400 reviews" />)}
      </div>
      <div className="hidden pt-1 md:w-1/2 md:block">
        <div className="w-full overflow-hidden rounded-xl">
          <YouTubeVideo videoID={LANDING_VIDEO} />
        </div>
      </div>
    </div>
  </div>
);

export default CoachLandingHero;
