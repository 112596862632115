import React from 'react';
import {Disclosure} from '@headlessui/react';
import cx from 'classnames';

import {FAQPage} from './LinkedData';
import {ChevronIcon} from './svg';

export default function FAQSection({items, includeMetadata = false}) {
  return (
    <div className="w-full mx-auto mt-4">
      {includeMetadata && <FAQPage items={items} />}
      <dl className="space-y-6 divide-y divide-gray-200">
        {items.map((faq) => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({open}) => (
              <>
                <dt className="text-lg">
                  <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                    <span className="font-medium text-gray-900">{faq.question}</span>
                    <span className="ml-6 h-7 flex items-center">
                      <ChevronIcon
                        className={cx(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12" unmount={false}>
                  <p className="text-base text-gray-500">{faq.answer}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>
  );
}
