import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {useRouter} from 'next/router';
import _ from 'lodash';

import Link from '../../components/Link';
import CoachPackageCard from '../../components/CoachPackageCard';
import CoachCard from '../../components/CoachCard';
import Button, {ButtonTypes} from '../../components/ButtonNew';
import DocumentHeader from '../../components/DocumentHeader';
import CoachesProvider, {CoachesContext} from '../../components/CoachesProvider';
import CoachingFilters from './CoachingFilters';
import CoachLandingHero from './CoachLandingHero';
import FAQSection from '../../components/FAQSection';
import {useAnalytics, useAppContext} from '../../hooks';
import {BASE_URL} from '../../constants/config';
import {airtableTagToDbTag} from '../../../../lib/utils';
import {airtableRoleToRoleMap} from '../../../../lib/constants/airtableTags';
import {getRecommendations} from '../../api/api';
import {
  CoachingFAQs, CoachingService, AirtableServiceNames,
} from '../../constants/coaching';
import {shouldShowUniversityCoachingBanner} from '../../util';
import {getCanonicalURL} from '../../util/seo';
import {StarIcon} from '../../components/svg';

const Placeholder = () => (
  <div className="flex flex-col h-full text-left p-6 pb-5 shadow rounded border border-gray-200 animate-pulse">
    <div>
      <span className="block h-12 w-12 bg-gray-100 rounded" />
      <span className="block h-8 bg-gray-100 mt-4 mb-3 w-2/3 rounded" />
    </div>
    <div className="flex flex-col justify-between h-full">
      <span className="h-4 w-full bg-gray-100 rounded mb-2" />
      <span className="h-4 w-3/4 bg-gray-100 rounded mb-2" />
      <span className="h-4 w-3/4 bg-gray-100 rounded mb-4" />
    </div>
  </div>
);

const filterCoaches = (coaches = [], {
  category, company, role, skill, industry,
}) => coaches.filter((coach) => {
  const services = coach.Services?.map((s) => AirtableServiceNames[s]) || [];
  const companies = coach.Companies?.map(airtableTagToDbTag) || [];
  const roles = _.compact(_.map(coach.Role, (r) => airtableRoleToRoleMap[r])) || [];
  const skills = _.flatMap(coach.Skills, 'id') || [];
  const industries = _.flatMap(coach.Industries, 'id') || [];

  if (category && !services.includes(category)) return false;

  if (category !== CoachingService.NEGOTIATION) {
    if (company && !(companies.includes(company))) return false;
    if (role && !(roles.includes(role))) return false;
    if (skill && !(skills.includes(skill))) return false;
    if (industry && !(industries.includes(industry))) return false;
  }

  return true;
});

const NegotiationTestimonial = () => (
  <section className="bg-gray-100 mb-12 -mx-6">
    <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 md:flex md:flex-col md:py-10">
      <div className="flex items-center justify-center mx-auto mb-6">
        <StarIcon className="h-6 w-6 text-yellow-400" />
        <StarIcon className="h-6 w-6 text-yellow-400" />
        <StarIcon className="h-6 w-6 text-yellow-400" />
        <StarIcon className="h-6 w-6 text-yellow-400" />
        <StarIcon className="h-6 w-6 text-yellow-400" />
        <span className="hidden sm:inline ml-2 text-gray-500 font-semibold">99% success rate for hundreds of offers</span>
      </div>
      <div className="relative text-2xl font-medium text-gray-900 md:flex-grow">
        <p className="relative text-center">
          &ldquo;My negotiation coach was truly an expert. I negotiated a $50k increase in my salary
          and I couldn&apos;t have done it without Exponent!&rdquo;
        </p>
      </div>
      <div className="mt-4 flex justify-center items-center">
        <div className="flex flex-col sm:flex-row">
          <div className="text-base font-medium text-gray-500 ml-4 sm:ml-2">— Sonia, Senior Product Manager</div>
        </div>
      </div>
    </div>
  </section>
);

const PageHeaders = {
  default: {
    title: 'Coaching and Mock Interviews for Your Tech Job',
    header: 'Expert coaching, mock interviews, and more',
    subheader: 'Connect with an expert in your field to practice mock interviews, review your resume, or create a study plan.',
    showTestimonial: true,
  },
  [CoachingService.MOCK_INTERVIEW]: {
    title: 'Coaching and Mock Interviews for Your Tech Job',
    header: 'Expert coaching, mock interviews, and more',
    subheader: 'Connect with an expert in your field to practice mock interviews, review your resume, or create a study plan.',
    showTestimonial: true,
  },
  [CoachingService.RESUME_REVIEW]: {
    title: 'Coaching and Mock Interviews for Your Tech Job',
    header: 'Get a resume review from a tech hiring manager',
    subheader: 'Your resume is your first impression. Get more offers with a professional review today.',
    showTestimonial: true,
  },
  [CoachingService.NEGOTIATION]: {
    title: 'Tech Salary Negotiation Coaching',
    header: 'Maximize your tech salary and compensation.',
    subheader: 'Don’t leave money on the table—greatly increase your offers with professional salary negotiation help. Money-back guarantee.',
    showTestimonial: false,
  },
  [CoachingService.CAREER_COACHING]: {
    title: 'Coaching and Mock Interviews for Your Tech Job',
    header: 'Level up your career with help from an expert mentor',
    subheader: 'Learn anything you need to know from our team of tech professionals.',
    showTestimonial: true,
  },
  [CoachingService.COMMUNICATION]: {
    title: 'Communication Coaching for Your Tech Job',
    header: 'Level up your career with help from an expert mentor',
    subheader: 'Learn anything you need to know from our team of tech professionals.',
    showTestimonial: false,
  },
};

const CoachingPageComponent = () => {
  const {
    features,
    onCheckout,
    catalog,
    currentUser,
  } = useAppContext();

  const {
    coaches,
    companies: companyLogos,
    tags,
  } = useContext(CoachesContext);

  const router = useRouter();
  const {tracker} = useAnalytics();
  const {
    role, company, category, skill, industry,
  } = router.query;
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [recommendedCoaches, setRecommendedCoaches] = useState([]);
  const coachesEl = useRef();

  useEffect(() => {
    let filtered = filterCoaches(coaches, {
      category,
      company,
      role,
      skill,
      industry,
    });

    if (recommendedCoaches?.length > 0) {
      filtered = _.sortBy(filtered, [
        (coach) => !recommendedCoaches.includes(coach.slug), // Recommended coaches first
        (coach) => ((
          coach.Services?.includes('Resume Review')
          || coach.Services?.includes('Resume Review Call')
        ) ? 1 : 0), // Push these services to the end
      ]);
    }

    setFilteredCoaches(filtered);
  }, [coaches, company, role, category, skill, industry, recommendedCoaches]);

  useEffect(() => {
    if (!router.isReady) return;
    tracker.coachLandingView({
      company, role, category, skill, industry,
    });
  }, [company, role, category, skill, industry, router, tracker]);

  useEffect(() => {
    if (currentUser) {
      getRecommendations().then((result) => {
        setRecommendedCoaches(result?.coaches.map((coach) => coach.slug));
      });
    }
  }, [currentUser]);

  const showPackage = (
    features?.coaching_package === 'on'
    && category !== CoachingService.NEGOTIATION
  );

  const showNegotiationPackage = (
    features?.coaching_package === 'on'
    && category === CoachingService.NEGOTIATION
  );

  const onClickPackage = (quantity = 1) => {
    tracker.coachClickPackage({quantity});
    const cancelUrl = `${BASE_URL}/coaching?payment_canceled=true`;
    const products = [{id: catalog.COACHING_SESSION.name, quantity}];
    onCheckout(products, currentUser, {cancelUrl});
  };

  const chooseCoach = () => {
    let selectedCoach;
    const interviewCompanies = currentUser?.profile?.interview_companies;
    // Match based on user's interested companies
    if (interviewCompanies) {
      selectedCoach = filteredCoaches.find((coach) => {
        const companies = coach.Companies?.map(airtableTagToDbTag) || [];
        const intersection = _.intersection(companies, interviewCompanies);
        return intersection.length > 0;
      });
    }

    // Match based on number of positive feedback
    if (!selectedCoach) {
      selectedCoach = _.maxBy(filteredCoaches, (coach) => (coach['Highlights (from Ratings from Students)'] || []).length);
    }

    router.push(`/coach/${selectedCoach.slug}`);
  };

  const onClickCoach = (coach) => (e) => {
    e.stopPropagation();
    tracker.coachClickProfile({
      coach: coach.slug,
      recommended: recommendedCoaches.includes(coach.slug),
      category,
      company,
      role,
    });
    router.push(`/coach/${coach.slug}`);
  };

  const scrollToCoaches = () => coachesEl?.current.scrollIntoView({behavior: 'smooth'});

  // Update URL query when filters change
  const onFilter = (param, value) => {
    const query = {...router.query};
    if (!value) {
      delete query[param];
    } else {
      query[param] = value;
    }
    router.push({
      pathname: router.pathname,
      query,
    }, null, {shallow: true});
  };

  const onClearFilters = () => {
    const query = _.without(router.query, ['role', 'company']);
    router.push({
      pathname: router.pathname,
      query,
    }, null, {shallow: true});
  };

  let {
    title, header, subheader,
  } = PageHeaders[category] || PageHeaders.default;

  const {showTestimonial} = PageHeaders[category] || PageHeaders.default;

  const FAQs = category ? CoachingFAQs.filter((FAQ) => FAQ.category.includes(category))
    : CoachingFAQs;

  if (tags) {
    let formattedRole = '';
    let formattedCompany = '';

    if (role) formattedRole = _.find(tags.role, ['id', role])?.name ?? '';
    if (company) formattedCompany = _.find(tags.company, ['id', company])?.name ?? '';

    if (role || company) {
      title = `${formattedCompany ? `${formattedCompany} ` : ''}${formattedRole ? `${formattedRole} ` : ''}Coaching and Mock Interviews`;
      subheader = `Practice mock interviews, review your resume, or create a study plan with an expert ${formattedCompany ? `${formattedCompany} ` : ''}${formattedRole ? `${formattedRole.toLowerCase()} ` : ''}coach.`;
      header = `Get expert coaching for ${formattedCompany ? `${formattedCompany} ` : ''}${formattedRole ? `${formattedRole.toLowerCase()} ` : ''}interviews`;
    }
  }

  return (
    <div className="CoachingSearchPage px-6">
      <DocumentHeader
        title={title}
        metaDescription={subheader}
        canonicalUrl={getCanonicalURL(router.asPath, ['role', 'company'])}
      />

      {shouldShowUniversityCoachingBanner(currentUser) && (
        <div className="-mx-6 flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="w-full text-sm leading-6 text-white text-center">
            Note: Coaching packages are not included in your university
            membership and can be purchased separately.
          </p>
        </div>
      )}

      <CoachLandingHero
        pageTitle={header}
        pageSubtitle={subheader}
        onClickCTA={scrollToCoaches}
        showTestimonial={showTestimonial}
      />
      {category === CoachingService.NEGOTIATION && (<NegotiationTestimonial />)}
      <div className="mx-auto mb-12 flex flex-col max-w-7xl" ref={coachesEl}>
        <span className="mb-2 text-2xl font-bold">Browse all coaches</span>
        {!coaches ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {_.times(6, (i) => <Placeholder key={i} />)}
          </div>
        ) : (
          <div className="space-y-4 -mx-6 px-6">
            <CoachingFilters
              role={role}
              company={company}
              category={category}
              skill={skill}
              industry={industry}
              onChange={onFilter}
            />
            {filteredCoaches.length === 0 && (
              <div className="flex flex-col w-full text-center text-base text-gray-500 p-10 border-3 border-dashed border-gray-300 rounded-lg">
                Sorry, we couldn&rsquo;t find any coaches that match those filters.
                <Button buttonType={ButtonTypes.TERTIARY} onClick={onClearFilters}>
                  Reset search
                </Button>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {showPackage && filteredCoaches.length !== 0 && (
                <CoachPackageCard
                  name="Save with our discounted 5-session package"
                  description="Buy sessions now and get matched with the right coach after purchase. Use at any time and schedule at your convenience."
                  onClick={() => onClickPackage(5)}
                />
              )}
              {showNegotiationPackage && filteredCoaches.length !== 0 && (
                <CoachPackageCard
                  name="Not sure? Let us pick the best negotiator for you."
                  description="We'll match you with an expert negotiator involving as many back-and-forth emails and calls as you need to increase your offer. Or your money back."
                  onClick={() => chooseCoach()}
                />
              )}
              {filteredCoaches.map((coach) => (
                <CoachCard
                  key={coach.slug}
                  name={coach.Name}
                  title={coach.Title}
                  image={coach.Photo?.[0].url}
                  href={`/coach/${coach.slug}`}
                  companies={coach.Companies}
                  companyLogos={companyLogos}
                  skills={coach.Skills}
                  services={coach.Services}
                  rating={coach.Rating}
                  description={coach['Brief Bio']}
                  numSessions={coach['Total number of sessions completed']}
                  recommended={recommendedCoaches.includes(coach.slug)}
                  onClick={onClickCoach(coach)}
                />
              ))}
            </div>
          </div>
        )}
        <div className="mt-16 flex flex-col w-full items-center">
          <div className="w-full flex flex-col max-w-3xl">
            <h2 className="text-xl md:text-2xl font-extrabold text-black">Frequently asked questions</h2>
            <FAQSection items={FAQs} />
          </div>
          <div className="py-10 flex flex-col text-center">
            <h2 className="mb-2 text-xl md:text-2xl font-extrabold text-black">Explore coaching by roles</h2>
            <ul>
              {tags?.role.map((tag) => (
                <li>
                  <Link className="text-sm md:text-base text-indigo-600 font-medium" href={`/coaching?role=${tag.id}`}>
                    {tag.name}
                    {' '}
                    Coaching
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoachingPage = (props) => (
  <CoachesProvider>
    <CoachingPageComponent {...props} />
  </CoachesProvider>
);

export default CoachingPage;
