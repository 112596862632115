import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import Card from './Card';
import Button, {ButtonSizes} from './ButtonNew';
import {ShortRightArrowIcon} from './svg';

const CoachPackageCard = ({
  name,
  description,
  onClick,
}) => {
  const handleButtonClick = (event) => {
    // Prevent the click event from reaching the Card
    event.stopPropagation();
    onClick();
  };

  return (
    <Card
      className="relative flex flex-col overflow-hidden cursor-pointer hover:shadow-md transition duration-50 group"
      onClick={onClick}
      border
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex flex-col">
          <div className="flex">
            <h3 className="text-xl font-bold">
              {name}
            </h3>
          </div>
          <span className="my-2 mb-4 text-gray-500 font-medium">
            {description}
          </span>
        </div>
        <Button
          size={ButtonSizes.LARGE}
          className="w-full mt-4"
          onClick={handleButtonClick}
        >
          Book now
          <ShortRightArrowIcon stroke="#ffffff" className="w-3 ml-1.5" />
        </Button>
      </div>
    </Card>
  );
};

export default CoachPackageCard;

CoachPackageCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CoachPackageCard.defaultProps = {
  onClick: undefined,
};
