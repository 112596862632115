import React, {useState, useEffect} from 'react';
import Plyr from 'plyr-react';

const MemoizedPlyr = React.memo(({videoID}) => (
  <Plyr
    options={{
      controls: [
        'play',
        'play-large',
        'progress',
        'duration',
        'mute',
        'volume',
        'captions',
        'settings',
        'fullscreen',
      ],
    }}
    source={{
      type: 'video',
      sources: [
        {
          src: videoID,
          provider: 'youtube',
        },
      ],
    }}
  />
));

const YouTubeVideo = ({
  videoID,
  onView,
  onPlay,
}) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    onView?.();
  }, []);

  const handlePlay = () => {
    if (!clicked) {
      onPlay?.();
      setClicked(true);
    }
  };

  return (
    <button
      className="flex relative justify-center rounded overflow-hidden cursor-pointer w-full"
      onClick={handlePlay}
      type="button"
      aria-label="Play video"
    >
      <div
        className="w-full"
        style={{'--plyr-color-main': '#5524e0'}}
      >
        <MemoizedPlyr videoID={videoID} />
      </div>
    </button>
  );
};

export default YouTubeVideo;
