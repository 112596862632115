// maps role tags from db to coaching Airtable db 'Role'
const roleToAirtableRoleMap = {
  'account-manager': ['Management'],
  'backend-engineer': ['Backend Engineer', 'Software Engineer'],
  bizops: ['BizOps', 'Product Operations'],
  'business-analyst': ['BizOps'],
  'business-development': ['Business Development'],
  'customer-success-manager': ['Sales'],
  'data-analyst': ['Data Analyst'],
  'data-engineer': ['Data Science', 'Data Analyst'],
  'data-science': ['Data Science'],
  'design-manager': ['Design'],
  devops: ['Engineering Manager', 'Site Reliability Engineer', 'Software Engineer', 'Technical Program Manager'],
  em: ['Engineering Manager'],
  'finance-strategy': ['Management'],
  'frontend-engineer': ['Frontend Engineer', 'Software Engineer'],
  'growth-marketing-manager': ['Product Marketing Manager', 'Product Manager'],
  'hardware-engineer': [],
  'investment-banking': ['Investment Banking'],
  'marketing-manager': ['Product Marketing Manager', 'Product Manager'],
  'ml-engineer': ['ML Engineer', 'Data Science', 'Data Analyst'],
  'mobile-engineer': ['Software Engineer'],
  pm: ['Product Manager', 'Product Marketing Manager'],
  pmm: ['Product Marketing Manager', 'Product Manager'],
  'product-analyst': ['Product Manager'],
  'product-designer': ['Product Design'],
  'private-equity': ['Private Equity'],
  'qa-engineer': ['Software Engineer'],
  security: ['Software Engineer'],
  'solutions-architect': ['Solutions Architect'],
  sre: ['Site Reliability Engineer'],
  swe: ['Software Engineer'],
  'test-engineer': ['Software Engineer'],
  tpm: ['Technical Program Manager'],
  'user-researcher': [],
  'visual-designer': ['Design'],
};

const airtableRoleToRoleMap = {
  'Account Manager': 'account-manager',
  'Backend Engineer': 'backend-engineer',
  BizOps: 'bizops',
  'Business Development': 'business-development',
  'Communication Specialist': '',
  'Data Analyst': 'data-analyst',
  'Data Engineer': 'data-engineer',
  'Data Science': 'data-science',
  Design: 'design',
  DevOps: 'devops',
  'Engineering Manager': 'em',
  'Investment Banking': 'investment-banking',
  'ML Engineer': 'ml-engineer',
  Management: 'management',
  'Private Equity': 'private-equity',
  'Product Design': 'product-design',
  'Product Operations': 'operations',
  'Product Manager': 'pm',
  'Product Marketing Manager': 'pmm',
  'Project Manager': 'management',
  Recruiter: 'recruiter',
  Sales: 'sales',
  'Site Reliability Engineer': 'sre',
  'Software Engineer': 'swe',
  'Solutions Architect': 'solutions-architect',
  'System Design': 'system-design',
  'Technical Program Manager': 'tpm',
};

module.exports = {
  roleToAirtableRoleMap,
  airtableRoleToRoleMap,
};
