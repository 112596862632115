import React, {useContext} from 'react';
import {useRouter} from 'next/router';
import cx from 'classnames';

import DropdownInput from '../../components/DropdownInput';
import {CoachesContext} from '../../components/CoachesProvider';
import Button, {ButtonTypes} from '../../components/ButtonNew';
import {CoachingService} from '../../constants/coaching';
import {createURLWithParams} from '../../util';

const CoachingFilters = ({
  role,
  company,
  category,
  skill,
  industry,
  onChange,
}) => {
  const router = useRouter();
  const {tags} = useContext(CoachesContext);

  const handleCategoryClick = (e, value) => {
    // Applies new category or clears category if active
    e.preventDefault();
    onChange('category', category === value ? null : value);
  };

  const toRoleOption = ({name, id}) => ({
    label: name,
    value: id,
    url: createURLWithParams(router.asPath, {role: id}),
  });
  const toCompanyOption = ({name, id}) => ({
    label: name,
    value: id,
    url: createURLWithParams(router.asPath, {company: id}),
  });
  const toSkillOption = ({name, id}) => ({
    label: name,
    value: id,
    url: createURLWithParams(router.asPath, {skill: id}),
  });
  const toIndustryOption = ({name, id}) => ({
    label: name,
    value: id,
    url: createURLWithParams(router.asPath, {industry: id}),
  });

  return (
    <div className="my-2 py-1 px-6 -mx-6 space-x-2 flex overflow-auto md:overflow-visible scrollbar-hidden">
      <DropdownInput
        value={role}
        placeholder="Role"
        options={tags ? tags.role.map(toRoleOption).filter((r) => r.label !== 'Recruiter') : []}
        onChange={(val) => onChange('role', val)}
      />
      <DropdownInput
        value={company}
        placeholder="Company"
        options={tags ? tags.company.map(toCompanyOption) : []}
        onChange={(val) => onChange('company', val)}
        showFilter
      />
      <DropdownInput
        value={skill}
        placeholder="Skill"
        options={tags ? tags.skill.map(toSkillOption) : []}
        onChange={(val) => onChange('skill', val)}
        showFilter
      />
      <DropdownInput
        value={industry}
        placeholder="Industry"
        options={tags ? tags.industry.map(toIndustryOption) : []}
        onChange={(val) => onChange('industry', val)}
      />
      <Button
        buttonType={ButtonTypes.SECONDARY}
        onClick={(e) => handleCategoryClick(e, CoachingService.MOCK_INTERVIEW)}
        href={`/coaching?category=${CoachingService.MOCK_INTERVIEW}`}
        className={cx('pl-4 whitespace-nowrap', {
          'text-indigo-600 border-indigo-600': category === CoachingService.MOCK_INTERVIEW,
        })}
      >
        💬 &nbsp;Interviewing
      </Button>
      <Button
        buttonType={ButtonTypes.SECONDARY}
        onClick={(e) => handleCategoryClick(e, CoachingService.CAREER_COACHING)}
        href={`/coaching?category=${CoachingService.CAREER_COACHING}`}
        className={cx('pl-4 whitespace-nowrap', {
          'text-indigo-600 border-indigo-600': category === CoachingService.CAREER_COACHING,
        })}
      >
        💼 &nbsp;Career
      </Button>
      <Button
        buttonType={ButtonTypes.SECONDARY}
        onClick={(e) => handleCategoryClick(e, CoachingService.NEGOTIATION)}
        href={`/coaching?category=${CoachingService.NEGOTIATION}`}
        className={cx('pl-4 whitespace-nowrap', {
          'text-indigo border-indigo-600': category === CoachingService.NEGOTIATION,
        })}
      >
        💸 &nbsp;Negotiation
      </Button>
      <Button
        buttonType={ButtonTypes.SECONDARY}
        onClick={(e) => handleCategoryClick(e, CoachingService.COMMUNICATION)}
        href={`/coaching?category=${CoachingService.COMMUNICATION}`}
        className={cx('pl-4 whitespace-nowrap', {
          'text-indigo border-indigo-600': category === CoachingService.COMMUNICATION,
        })}
      >
        🗣️ &nbsp;Communication
      </Button>
      <Button
        buttonType={ButtonTypes.SECONDARY}
        onClick={(e) => handleCategoryClick(e, CoachingService.RESUME_REVIEW)}
        href={`/coaching?category=${CoachingService.RESUME_REVIEW}`}
        className={cx('pl-4 whitespace-nowrap', {
          'text-indigo border-indigo-600': category === CoachingService.RESUME_REVIEW,
        })}
      >
        📝 &nbsp;Resume
      </Button>
    </div>
  );
};

export default CoachingFilters;
