import React from 'react';

import Link from '../components/Link';

export const CoachingService = {
  MOCK_INTERVIEW: 'mock_interviews',
  NEGOTIATION: 'negotiation',
  CAREER_COACHING: 'career_coaching',
  RESUME_REVIEW: 'resume_review',
  COMMUNICATION: 'communication_skills',
};

export const ServiceNames = {
  [CoachingService.MOCK_INTERVIEW]: 'Mock Interviews',
  [CoachingService.NEGOTIATION]: 'Negotiation',
  [CoachingService.CAREER_COACHING]: 'Career Coaching',
  [CoachingService.RESUME_REVIEW]: 'Resume Review',
  [CoachingService.COMMUNICATION]: 'Communication Skills',
};

export const AirtableServiceNames = {
  'Mock Interviews': CoachingService.MOCK_INTERVIEW,
  'Career Coaching': CoachingService.CAREER_COACHING,
  'Resume Review': CoachingService.RESUME_REVIEW,
  'Resume Review Call': CoachingService.RESUME_REVIEW,
  Negotiation: CoachingService.NEGOTIATION,
  'Communication Skills': CoachingService.COMMUNICATION,
};

export const LANDING_VIDEO = 'qFfiPtnfljs';

export const CoachingFAQs = [{
  question: "How does Exponent's interviewing coaching work?",
  answer: `Our interviewing coaching is a core pillar of the Exponent interview preparation experience.
      In addition to self-studying on our platform and practicing with mock interviews,
      you can give yourself the best chance of landing your dream role by seeking feedback from
      our expert interview coaches who can help level-up your interview presence, refine your
      responses, and highlight any pitfalls. Most students book 3-5 sessions to make the most of
      the coaching experience.`,
  category: [CoachingService.MOCK_INTERVIEW],
}, {
  question: 'What is included in the personalized coaching sessions?',
  answer: "Before and during the 60 minute session, you'll discuss your goals and your coach will customize the session to tailor to your areas of improvement. During and after the session, you'll receive detailed feedback and guidance on how to improve. Some examples of what we might cover in our sessions include designing and specifying a product together, mini-brainstorm exercises, or collaborative product strategy sessions.",
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.CAREER_COACHING],
}, {
  question: 'Who are the Exponent Coaches?',
  answer: 'Exponent Coaches are experienced product managers, engineers, and industry experts who have been hand-picked and vetted by Exponent. Coaches are rigorously selected, and you can review their full profile before booking.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.CAREER_COACHING],
}, {
  question: 'How does Exponent\'s negotiation coaching work?',
  answer: 'Exponent\'s negotiation coaching package is designed to help you achieve the best possible outcome in your negotiations. The coaching package includes unlimited time and meeting sessions with a trained negotiation coach, who will use their comprehensive and in-house data set of salary information to help you get a higher salary number—or your money back. Your coach will work with you to develop strategies and tactics to help you negotiate the best possible offer, and will be available for ongoing support and guidance as needed.',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'What is the difference between the negotiation package and the 1-hour negotiation coaching session?',
  answer: 'While our negotiation package is our most popular and full-service offering, if you\'re seeking a brief consultation with a negotiation coach, you can book a one-hour session to discuss your offer details.',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'What is the money-back guarantee?',
  answer: 'Exponent\'s negotiation coaching package (not the 1-hour sessions) is guaranteed to increase your offer. If it does not, we will give you your money back!',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'What is the difference between salary and total compensation?',
  answer: 'Salary refers to the amount of money an employee is paid on an annual or hourly basis, while total compensation includes all of the monetary and non-monetary benefits an employee receives in addition to their salary, such as bonuses, stock options, health insurance, retirement plans, and other perks.',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'Can I negotiate my job offer?',
  answer: 'Every offer and company are unique. If you receive a job offer from a FAANG company, you may be able to negotiate a higher salary or stock-option package. However, if you’re interviewing with a startup, the situation may be different. Startup equity and compensation vary by industry and role. Working with a negotiation coach can help you identify which aspects of your offer are negotiable and which are not, so that you can focus on getting exactly what you want for your offer and your career.',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'What will I learn in a negotiation coaching session?',
  answer: 'In a 1-hour call, you will learn the basics of negotiating a tech job offer. This includes understanding your worth, researching achievable salaries, and knowing how to ask for what you deserve in follow-up calls and emails with recruiters or hiring managers. Whether you are negotiating for a FAANG+ job or a position at a tech startup as a junior or senior candidate, a negotiation coach can help you secure the highest possible salary and total compensation package.',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'Who are the Exponent Negotiation Coaches?',
  answer: 'Exponent Negotiation Coaches are experienced director-level talent professionals who have helped top-tier tech companies determine salary benchmarks and negotiate with candidates. Coaches are rigorously selected, and you can review their full profile before booking.',
  category: [CoachingService.NEGOTIATION],
}, {
  question: 'How does Exponent\'s career coaching work?',
  answer: 'Exponent\'s career coaching services are designed to help you advance in your tech career and improve your job performance. Your coach will work with you to identify career goals and develop a plan to achieve them, including strategies for improving job performance and advancing in your tech career. Additionally, your coach will offer ongoing support and guidance to help you navigate the challenges and opportunities of your tech career.',
  category: [CoachingService.CAREER_COACHING],
}, {
  question: 'Do unused sessions expire?',
  answer: 'Unused sessions never expire and can be redeemed for any type of 60-min coaching session.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}, {
  question: 'Can I get a refund on unused sessions?',
  answer: 'Unused sessions are eligible for a refund within six months of purchase. Please note that refunds for unused sessions in multi-session packs do not include the multi-session discount. Your refund will be calculated based on the face value of a session (inclusive of promotions), or the next closest pricing option. For example, if you purchased a 5-session pack and only redeemed 3 sessions, you will be refunded the price difference between the 5-session pack and the 3-session pack. For any questions, please email support@tryexponent.com.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}, {
  question: 'Does coaching work in all time zones?',
  answer: 'Yes! We have coaches who can service any time zone you are in. You can preview the upcoming availability for each coach on their profile page.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}, {
  question: 'I need coaching ASAP!',
  answer: 'You can preview the upcoming availability for each coach on their profile page.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}, {
  question: 'Does my coaching purchase include Exponent\'s membership?',
  answer: 'The purchase of a coaching session does not include Exponent\'s membership.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}, {
  question: 'What if I need to cancel my session or I miss my session?',
  answer: 'We understand that plans can change. We ask that you reschedule or cancel your session at least 24 hours ahead of time out of consideration for our coaches. Missed sessions or cancellations/reschedules within 24 hours will count as a used session.',
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}, {
  question: 'I have a question not answered here.',
  answer: (
    <span>
      Shoot us an email at
      {' '}
      <Link className="text-indigo" href="mailto:support@tryexponent.com">support@tryexponent.com</Link>
      .
    </span>),
  category: [CoachingService.MOCK_INTERVIEW, CoachingService.NEGOTIATION,
    CoachingService.CAREER_COACHING],
}];
