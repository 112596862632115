import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {getCoaches} from '../api/api';

export const CoachesContext = React.createContext({});

const CoachesProvider = (props) => {
  const [coaches, setCoaches] = useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  const [tags, setTags] = useState(undefined);

  useEffect(() => {
    getCoaches().then((data) => {
      setCoaches(data.coaches);
      setTags(data.tags);
      setCompanies(data.companies);
    });
  }, []);

  return (
    <CoachesContext.Provider
      value={{
        coaches,
        companies,
        tags,
      }}
    >
      {props.children}
    </CoachesContext.Provider>
  );
};

export default CoachesProvider;
