import React from 'react';
import {times} from 'lodash';

import {StarIcon} from './svg';

const Stars = ({stars = 5, rating}) => (
  <div className="flex flex-wrap justify-center space-2 md:justify-start md:items-center">
    <div>
      {times(stars, (i) => <StarIcon className="h-6 w-6 text-yellow-400" key={i} />)}
    </div>
    <span className="ml-2 text-gray-500 font-semibold">{rating}</span>
  </div>
);

export default Stars;
