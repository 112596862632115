import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import Card from './Card';
import Link from './Link';
import Button, {ButtonSizes} from './ButtonNew';
import {StarIcon, ShortRightArrowIcon} from './svg';
import {clampLines} from '../util';

export const Placeholder = () => (
  <div className="flex flex-col mb-4 border shadow rounded-lg overflow-hidden animate-pulse">
    <div className="w-full h-10 w-10 rounded-full bg-gray-100" />
    <div className="w-full flex-shrink-0 p-5">
      <div className="h-8 bg-gray-100 rounded w-4/5 mb-2" />
      <div className="h-4 bg-gray-100 rounded w-full mb-2" />
      <div className="h-4 bg-gray-100 rounded w-full mb-4" />
      <div className="h-12 bg-gray-100 rounded w-full" />
    </div>
  </div>
);

const formatCoachRating = (rating) => {
  if (!rating || Number.isNaN(Number(rating))) return 0;
  return (Math.round(Number(rating) * 10) / 10).toFixed(1);
};

const shouldShowRating = (rating, reviews) => rating > 0 && reviews > 0;

const CoachLogos = ({companies, companyLogos, limit = 5}) => {
  const logos = (companies || [])
    .map((name) => (companyLogos || {})[name.trim()])
    .filter(Boolean);

  const displayedLogos = logos.slice(0, limit);

  const hiddenCount = logos.length - displayedLogos.length;

  return (
    <div className="flex justify-start items-center flex-row-reverse pr-1 gap-1">
      {hiddenCount > 0 && (
        <div className="h-5 p-px flex items-center justify-center text-sm font-semibold text-gray-400">
          {`+${hiddenCount} more`}
        </div>
      )}
      {displayedLogos.map((logo) => (
        <img
          key={logo}
          className="h-5 w-5 p-px rounded-full bg-white"
          src={`${logo}?w=32`}
          alt=""
        />
      ))}
    </div>
  );
};

const CoachCard = ({
  name,
  image,
  title,
  recommended,
  description,
  companies,
  companyLogos,
  skills,
  rating,
  numSessions,
  href,
  onClick,
}) => (
  <Card
    className="relative flex flex-col overflow-hidden cursor-pointer hover:shadow-md transition duration-50 group"
    onClick={onClick}
    border
  >
    <div className="flex-col items-center w-full">
      <div className="flex relative mb-2">
        <img
          className="h-16 w-16 mr-4 object-cover rounded-full flex-shrink-0"
          src={image}
          alt={name}
          loading="lazy"
        />
      </div>
      {recommended && (
        <div className="absolute top-4 right-4 bg-indigo-200 text-indigo-600 text-xs font-medium rounded-lg px-2 py-1">
          Recommended
        </div>
      )}
    </div>
    <div className="flex flex-col justify-between w-full h-full">
      <div className="flex flex-col">
        <div className="flex">
          <Link href={href} onClick={onClick}>
            <h3 className="text-xl font-bold">
              {name}
            </h3>
          </Link>
          <span className="ml-2 flex">
            <CoachLogos companies={companies} companyLogos={companyLogos} />
          </span>
        </div>
        <span className="mb-2 text-base font-medium text-gray-500 leading-tight">
          {title}
        </span>

        {shouldShowRating(rating, numSessions) && (
          <div className="flex items-center">
            <StarIcon className="h-5 w-5 text-yellow-400 -ml-0.5" />
            <span className="ml-0.5 flex items-center text-gray-600 text-sm font-semibold gap-1">
              {`${formatCoachRating(rating)} rating`}
              {' '}
              <span className="font-light text-gray-500">
                {numSessions > 1 && `(${numSessions} sessions)`}
              </span>
            </span>
          </div>
        )}

        <span className="my-2 mb-4 text-gray-500 font-medium" style={clampLines(2)}>
          {description}
        </span>

        <div className="mb-2 flex flex-wrap gap-2 items-center text-gray-700 font-medium text-sm  whitespace-nowrap">
          {skills?.slice(0, 2).map((skill) => (
            <div className="bg-gray-200 text-gray-600 text-xs font-medium rounded-lg px-2 py-1" key={skill.id}>
              {skill.name}
            </div>
          ))}
          {skills?.length > 2 && (
            <div className="bg-gray-200 text-gray-600 text-xs font-medium rounded-lg px-2 py-1">
              {`+ ${skills.length - 2} more`}
            </div>
          )}
        </div>
      </div>
      <Button
        size={ButtonSizes.LARGE}
        className="w-full mt-4"
        href={href}
        onClick={onClick}
      >
        Book now
        <ShortRightArrowIcon stroke="#ffffff" className="w-3 ml-1.5" />
      </Button>
    </div>
  </Card>
);

export default CoachCard;

CoachCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.node,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.string),
  rating: PropTypes.number.isRequired,
  numSessions: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CoachCard.defaultProps = {
  onClick: undefined,
  companies: [],
};
